<template>
  <div class="password">
    <a-result class="password-box" title="修改密码" :sub-title="tip">
      <template #extra>
        <a-form-model
          class="password-form"
          ref="passwordForm"
          :model="form"
          :rules="rules"
          :label-col="{span: 0}"
          :wrapper-col="{span: 24}">
          <a-form-model-item label="" prop="first">
            <a-input size="large" type="password" v-model="form.first" placeholder="新密码" @keyup.enter="confirm">
              <a-icon slot="prefix" type="lock"/>
            </a-input>
          </a-form-model-item>
          <a-form-model-item label="" prop="second">
            <a-input size="large" type="password" v-model="form.second" placeholder="再次输入新密码" @keyup.enter="confirm">
              <a-icon slot="prefix" type="lock" />
            </a-input>
          </a-form-model-item>
          <a-button size="large" type="primary" block @click="confirm">确认修改</a-button>
        </a-form-model>
      </template>
    </a-result>
  </div>
</template>

<script>

    import {getUserId} from "../common/js/storage";

    export default {
        name: "Password",
        data() {
            return {
                form: {},
                rules: {
                    first: [{ required: true, message: '请输入新密码', trigger: 'blur' }],
                    second: [{ required: true, message: '请再次输入新密码', trigger: 'blur' }],
                },
                loading: false,
                type: 'simple'
            }
        },
        computed: {
            tip() {
                const type = this.type;
                let prefix = "";
                if(type) {
                    prefix = type == 'simple' ? '您的密码过于简单，请修改密码！' : '您的密码长时间未更换，请修改密码！';
                }
                return `${prefix}新密码必须包含大小写字母及特殊符号。`
            }
        },
        created() {
            this.type = this.$route.query.type || "";
        },
        methods: {
            confirm() {
                this.$refs.passwordForm.validate( valid => {
                    if (valid) {
                        const form = this.form;
                        if(form.first !== form.second) {
                            this.$message.warning("两次输入密码不一致，请重新输入");
                            return;
                        }
                        if(!/(?=.*[A-Z])(?=.*[a-z])(?=.*[^a-zA-Z0-9])/.test(form.first)) {
                            this.$message.warning("新密码必须包含大小写字母及特殊符号");
                            return;
                        }
                        const id = getUserId();
                        this.$axios({
                            url: `/admin/${id}`,
                            method: "PATCH",
                            data: {
                                password: form.second
                            }
                        }).then(() => {
                            this.$message.success("修改成功");
                            const path = this.$route.query.r || '/approve'
                            this.$router.push(path);
                        });
                    }
                })
            }
        }
    }
</script>

<style scoped lang="less">

  .password {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: @background-color-light;
  }
  .password-title {
    font-size: 20px;
    text-align: center;
  }

  .password-box {
    position: relative;
    margin-top: 40px;
    width: 400px;
  }
  .password-form {
    margin-top: 24px;
    text-align: left;
  }
</style>
